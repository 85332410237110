const Vorgang = (module.exports.Vorgang = {
	OFFEN: 'offen',
	KONDITIONEN_ERSTELLT: 'konditionenErstellt',
	KONDITIONEN_VERSCHICKT: 'konditionenVerschickt',
	ABGELEHNT: 'abgelehnt',
	VERTRAG_GEWUENSCHT: 'vertragGewuenscht',
	KREDITVERTRAG_ERSTELLT: 'kreditvertragErstellt',
	KREDITVERTRAG_VERSCHICKT: 'kreditvertragVerschickt',
	WIRD_FORTGEFUEHRT: 'wirdFortgefuehrt',
	DOKUMENTE_ERHALTEN: 'dokumenteErhalten',
	GENEHMIGT: 'genehmigt',
	AUSGEZAHLT: 'ausgezahlt',
	PRUEFUNG_NEGATIV: 'pruefungNegativ',
	RUECKTRITT: 'ruecktritt',
	KUNDEN_RUECKTRITT: 'kundenRuecktritt',
	UNTERLAGEN_NACHGEFORDERT: 'unterlagenNachgefordert'
});

module.exports.VorgangActionRequired = [
	Vorgang.OFFEN,
	Vorgang.KONDITIONEN_ERSTELLT,
	Vorgang.VERTRAG_GEWUENSCHT,
	Vorgang.DOKUMENTE_ERHALTEN,
	Vorgang.WIRD_FORTGEFUEHRT
];

module.exports.VorgangNoActionRequired = [
	Vorgang.KONDITIONEN_VERSCHICKT,
	Vorgang.KREDITVERTRAG_ERSTELLT,
	Vorgang.KREDITVERTRAG_VERSCHICKT,
	Vorgang.UNTERLAGEN_NACHGEFORDERT,
	Vorgang.GENEHMIGT
];
module.exports.VorgangFinished = [
	Vorgang.ABGELEHNT,
	Vorgang.AUSGEZAHLT,
	Vorgang.RUECKTRITT,
	Vorgang.KUNDEN_RUECKTRITT,
	Vorgang.PRUEFUNG_NEGATIV
];

module.exports.VorgangNextStep = {
	BANK: 'bank',
	DURCHBLICKER: 'durchblicker'
};

module.exports.VorgangTabs = {
	DATA: 'data',
	DOCUMENTS: 'documents',
	OFFERS: 'offers',
	COMPLETION: 'completion'
};

module.exports.DurchblickerStatusUpdates = {
	KUNDE_RUECKTRITT: 'Kundenrücktritt',
	// VERTRAG_VERSCHICKT wird benötigt zur unterscheidung des sub status, kein wirklicher durchblicker status
	VERTRAG_VERSCHICKT: 'Vertrag verschickt',
	UNTERLAGEN_ERHALTEN: 'Unterlagen erhalten',
	// KREDIT_ABGELEHNT: 'Kredit abgelehnt',
	GENEHMIGT: 'Genehmigt',
	ABGELEHNT: 'Abgelehnt',
	UNGUELTIG: 'Ungueltig'
};

module.exports.DurchblickerSubStatusUpdates = {
	VERTRAG_VERSCHICKT: 'Vertrag verschickt',
	VERTRAG_RETOUR: 'Vertrag retour'
};
