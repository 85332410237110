export default (data) => {
	return [
		{
			key: 'ruecktrittsGrund',
			type: 'select',
			name: 'Rücktrittsgrund',
			options: [
				{
					name: 'Angebot abgelaufen',
					value: 'Angebot abgelaufen'
				},
				{
					name: 'Unterlagen',
					value: 'Unterlagen'
				},
				{
					name: 'Doppelte Anfrage',
					value: 'Doppelte Anfrage'
				},

				{
					name: 'Bonität nicht ausreichend',
					value: 'Bonität nicht ausreichend'
				},
				{
					name: 'Unterlagen',
					value: 'Unterlagen'
				},
				{
					name: 'Fraud',
					value: 'Fraud'
				},
				{
					name: 'Kunde nicht erreichbar',
					value: 'Kunde nicht erreichbar'
				},
				{
					name: 'Kein Interesse mehr',
					value: 'Kein Interesse mehr'
				},
				{
					name: 'Kunde handelt auf fremde Rechnung',
					value: 'Kunde handelt auf fremde Rechnung'
				},
				{
					name: 'Doppelte Anfrage',
					value: 'Doppelte Anfrage'
				},
				{
					name: 'Sonstiges',
					value: 'Sonstiges'
				}
			]
		}
	];
};
