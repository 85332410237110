import nationen from '../mappings/nationen';
import wohnart from '../mappings/wohnart';
import beschaeftigungsart from '../mappings/beschaeftigungsart';
import branche from '../mappings/branche';
import familienstand from '../mappings/familienstand';
import pensionsauszahlendestelle from '../mappings/pensionsauszahlendestelle';
import schulbildung from '../mappings/schulbildung';
import kreditart from '../mappings/kreditart';
import { formatAmount } from '../../components/utils/formatting';

export default (data) => {
	const output = [];

	output.push({
		title: 'Kreditinformationen',
		items: [
			{
				key: 'Kreditbetrag',
				value: formatAmount(data.darlehensbetrag)
			},
			{
				key: 'Laufzeit (Monate)',
				value: data.laufzeit.toString()
			}
		]
	});

	output.push({
		title: 'Persönliche Daten',
		items: [
			{
				key: 'Name',
				value: [
					data.abschluss.person1.titel,
					data.abschluss.person1.vorname,
					data.abschluss.person1.nachname
				]
					.filter(Boolean)
					.join(' ')
			},
			{
				key: 'Titel',
				value: data.abschluss.person1.titel,
				hidden: true
			},
			{
				key: 'Vorname',
				value: data.abschluss.person1.vorname,
				hidden: true
			},
			{
				key: 'Nachname',
				value: data.abschluss.person1.nachname,
				hidden: true
			},
			{
				key: 'Geschlecht',
				value: data.abschluss.person1.geschlecht === 'maennlich' ? 'männlich' : 'weiblich'
			},
			{
				key: 'Geburtsdatum',
				value: new Date(data.abschluss.person1.geburtsdatum).toLocaleDateString('de-at')
			},
			{
				key: 'E-Mail',
				value: data.abschluss.person1.mail
			},
			{
				key: 'Telefonnummer',
				value: data.abschluss.person1.tel
			},
			{
				key: 'Geburtsname',
				value: data.abschluss.person1.geburtsname,
				hidden: !(
					data.abschluss.person1.geburtsname &&
					['verheiratet', 'eingetragenePartnerschaft', 'geschieden', 'verwitwet'].includes(
						data.abschluss.person1.familienstand
					)
				)
			},
			{
				key: 'Geburtsland',
				value: nationen[data.abschluss.person1.geburtsland]
			},
			{
				key: 'Geburtsort',
				value: data.abschluss.person1.geburtsort
			},
			{
				key: 'Staatsangehörigkeit',
				value: nationen[data.abschluss.person1.staatsangehoerigkeit]
			},
			{
				key: 'Familienstand',
				value: familienstand[data.abschluss.person1.familienstand]
			},
			{
				key: 'Schulbildung',
				value: schulbildung[data.abschluss.person1.schulbildung]
			}
		]
	});
	output.push({
		title: 'Bankdaten',
		items: [
			{
				key: 'IBAN',
				value: data.abschluss.iban
			},
			{
				key: 'Bank',
				value: data.abschluss.bank
			}
		]
	});

	if (data.abschluss.person1.wohnsitz) {
		output.push({
			title: 'Wohnsitz',
			items: [
				{
					key: 'Adresse',
					value: [
						(data.abschluss.person1.wohnsitz.adresse.text || '') + ',',
						data.abschluss.person1.wohnsitz.region.plz,
						data.abschluss.person1.wohnsitz.region.gemeinde
					]
						.filter(Boolean)
						.join(' ')
				},
				{
					key: 'Straße',
					value: data.abschluss.person1.wohnsitz.adresse.strasse,
					hidden: true
				},
				{
					key: 'Hausnummer',
					value: data.abschluss.person1.wohnsitz.adresse.hausnummer,
					hidden: true
				},
				{
					key: 'Stiege',
					value: data.abschluss.person1.wohnsitz.adresse.stiege,
					hidden: true
				},
				{
					key: 'Tür',
					value: data.abschluss.person1.wohnsitz.adresse.tuer,
					hidden: true
				},
				{
					key: 'PLZ',
					value: data.abschluss.person1.wohnsitz.region.plz,
					hidden: true
				},
				{
					key: 'Ort',
					value: data.abschluss.person1.wohnsitz.region.gemeinde,
					hidden: true
				},
				{
					key: 'wohnhaft seit',
					value: new Date(data.abschluss.person1.wohnsitz.wohnhaftseit).toLocaleDateString('de-at')
				},
				{
					key: 'Wohnart',
					value: wohnart[data.abschluss.person1.wohnsitz.wohnart]
				},
				{
					key: 'Wohnfläche (in m²)',
					value: data.abschluss.person1.wohnsitz.flaeche
						? data.abschluss.person1.wohnsitz.flaeche.toString()
						: '0'
				}
			]
		});
	}

	if (data.abschluss.person1.beschaeftigung) {
		output.push({
			title: 'Beschäftigung',
			items: [
				{
					key: 'Art der Beschäftigung',
					value: beschaeftigungsart[data.abschluss.person1.beschaeftigung.art]
				},
				{
					key: 'Branche',
					value: branche[data.abschluss.person1.beschaeftigung.branche],
					hidden: data.abschluss.person1.beschaeftigung.art === 'pensionist'
				},
				{
					key: 'beschäftigt seit',
					value: new Date(
						data.abschluss.person1.beschaeftigung.beschaeftigtseit
					).toLocaleDateString('de-at'),
					hidden: data.abschluss.person1.beschaeftigung.art === 'pensionist'
				},
				{
					key: 'Dienstgeber',
					value: data.abschluss.person1.beschaeftigung.dienstgeber,
					hidden: data.abschluss.person1.beschaeftigung.art === 'pensionist'
				},
				{
					key: 'Anschrift',
					value: [
						(data.abschluss.person1.beschaeftigung.adresse.text || '') + ',',
						data.abschluss.person1.beschaeftigung.region.plz,
						data.abschluss.person1.beschaeftigung.region.gemeinde
					]
						.filter(Boolean)
						.join(' '),
					hidden: data.abschluss.person1.beschaeftigung.art === 'pensionist'
				},
				{
					key: 'Straße',
					value: data.abschluss.person1.beschaeftigung.adresse.strasse,
					hidden: true
				},
				{
					key: 'Hausnummer',
					value: data.abschluss.person1.beschaeftigung.adresse.hausnummer,
					hidden: true
				},
				{
					key: 'PLZ',
					value: data.abschluss.person1.beschaeftigung.region.plz,
					hidden: true
				},
				{
					key: 'Ort',
					value: data.abschluss.person1.beschaeftigung.region.gemeinde,
					hidden: true
				},
				{
					key: 'Pensionsauzahlende Stelle',
					value:
						pensionsauszahlendestelle[
							data.abschluss.person1.beschaeftigung.pensionsauszahlendestelle
						],
					hidden: data.abschluss.person1.beschaeftigung.art !== 'pensionist'
				},
				{
					key: 'Kurzarbeit',
					value: data.abschluss.person1.beschaeftigung.kurzarbeit ? 'Ja' : 'Nein',
					hidden: data.abschluss.person1.beschaeftigung.art === 'pensionist'
				}
			]
		});
	}

	if (data.abschluss.person1.einkommen) {
		output.push({
			title: 'Einnahmen',
			items: [
				{
					key: data.abschluss.person1.einkommen.gleichbleibend
						? 'Nettoeinkommen'
						: 'Nettoeinkommen letzter Monat',
					value: formatAmount(data.abschluss.person1.einkommen.monat)
				},
				{
					key: 'Höhe Familienbonus',
					value: formatAmount(data.abschluss.person1.einkommen.fabohoehe),
					hidden: !data.abschluss.person1.einkommen.fabohoehe
				},
				{
					key: 'Nettoeinkommen vor 2 Monaten',
					value: formatAmount(data.abschluss.person1.einkommen.monat2),
					hidden: data.abschluss.person1.einkommen.gleichbleibend
				},
				{
					key: 'Nettoeinkommen vor 3 Monaten',
					value: formatAmount(data.abschluss.person1.einkommen.monat3),
					hidden: data.abschluss.person1.einkommen.gleichbleibend
				},
				{
					key: 'Zusatzeinkommen Unterhaltszahlungen',
					value: formatAmount(data.abschluss.person1.einkommen.zusatzeinkommen.unterhalt),
					hidden: !data.abschluss.person1.einkommen.zusatzeinkommen.unterhalt
				},
				{
					key: 'Zusatzeinkommen Mieteinnahmen',
					value: formatAmount(data.abschluss.person1.einkommen.zusatzeinkommen.mieteinnahme),
					hidden: !data.abschluss.person1.einkommen.zusatzeinkommen.mieteinnahme
				},
				{
					key: 'Zusatzeinkommen Nebenerwerbstätigkeit',
					value: formatAmount(data.abschluss.person1.einkommen.zusatzeinkommen.nebenerwerb),
					hidden: !data.abschluss.person1.einkommen.zusatzeinkommen.nebenerwerb
				},
				{
					key: 'Zusatzeinkommen Beihilfen',
					value: formatAmount(data.abschluss.person1.einkommen.zusatzeinkommen.beihilfe),
					hidden: !data.abschluss.person1.einkommen.zusatzeinkommen.beihilfe
				},
				{
					key: 'Zusatzeinkommen Sonstiges',
					value: formatAmount(data.abschluss.person1.einkommen.zusatzeinkommen.sonstiges),
					hidden: !data.abschluss.person1.einkommen.zusatzeinkommen.sonstiges
				}
			]
		});
	}
	if (data.abschluss.person1.ausgaben) {
		output.push({
			title: 'Ausgaben',
			items: [
				{
					key: 'Wohnkosten',
					value: formatAmount(data.abschluss.person1.ausgaben.wohnkosten)
				},
				{
					key: 'Anzahl unterhaltspflichtiger Personen',
					value: data.abschluss.person1.ausgaben.personenunterhalt
						? data.abschluss.person1.ausgaben.personenunterhalt.toString()
						: '0'
				},
				{
					key: 'Unterhaltszahlungen',
					value: formatAmount(data.abschluss.person1.ausgaben.unterhalt),
					hidden: data.abschluss.person1.ausgaben.personenunterhalt === 0
				},
				{
					key: 'Alleinverdiener',
					value: data.abschluss.person1.ausgaben.alleinverdiener ? 'Ja' : 'Nein',
					hidden: !['verheiratet', 'eingetragenePartnerschaft'].includes(
						data.abschluss.person1.familienstand
					)
				},
				{
					key: 'Anzahl Autos',
					value: data.abschluss.person1.ausgaben.auto
						? data.abschluss.person1.ausgaben.auto.toString()
						: '0'
				}
			]
		});
	}
	if (data.abschluss.kredite) {
		output.push({
			title: 'Bestehende Kredite',
			items: [
				{
					key: 'Anzahl bestehender Kredite',
					value: data.abschluss.kredite.anzahl
				}
			]
		});
	}

	if (data.abschluss.kredite && data.abschluss.kredite.anzahl > 0) {
		output.push({
			title: 'Kredit 1',
			items: [
				{
					key: 'Art des Kredits',
					value: kreditart[data.abschluss.kredite.kredit1.art]
				},
				{
					key: 'Kreditrate',
					value: formatAmount(data.abschluss.kredite.kredit1.rate)
				},
				{
					key: 'Umschuldung erwünscht',
					value: data.abschluss.kredite.kredit1.umschuldung ? 'Ja' : 'Nein',
					hidden: data.abschluss.kredite.kredit1.art !== 'konsumkredit'
				},
				{
					key: 'Geschätzte Restschuld',
					value: formatAmount(data.abschluss.kredite.kredit1.restschuld),
					hidden: data.abschluss.kredite.kredit1.art === 'leasing'
				}
			]
		});
	}

	if (data.abschluss.kredite && data.abschluss.kredite.anzahl > 1) {
		output.push({
			title: 'Kredit 2',
			items: [
				{
					key: 'Art des Kredits',
					value: kreditart[data.abschluss.kredite.kredit2.art]
				},
				{
					key: 'Kreditrate',
					value: formatAmount(data.abschluss.kredite.kredit2.rate)
				},
				{
					key: 'Umschuldung erwünscht',
					value: data.abschluss.kredite.kredit2.umschuldung ? 'Ja' : 'Nein',
					hidden: data.abschluss.kredite.kredit2.art !== 'konsumkredit'
				},
				{
					key: 'Geschätzte Restschuld',
					value: formatAmount(data.abschluss.kredite.kredit2.restschuld),
					hidden: data.abschluss.kredite.kredit2.art === 'leasing'
				}
			]
		});
	}

	if (data.abschluss.kredite && data.abschluss.kredite.anzahl > 2) {
		output.push({
			title: 'Kredit 3',
			items: [
				{
					key: 'Art des Kredits',
					value: kreditart[data.abschluss.kredite.kredit3.art]
				},
				{
					key: 'Kreditrate',
					value: formatAmount(data.abschluss.kredite.kredit3.rate)
				},
				{
					key: 'Umschuldung erwünscht',
					value: data.abschluss.kredite.kredit3.umschuldung ? 'Ja' : 'Nein',
					hidden: data.abschluss.kredite.kredit3.art !== 'konsumkredit'
				},
				{
					key: 'Geschätzte Restschuld',
					value: formatAmount(data.abschluss.kredite.kredit3.restschuld),
					hidden: data.abschluss.kredite.kredit3.art === 'leasing'
				}
			]
		});
	}
	return output;
};
