import UserContext from '../UserContext';
import { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import Status, { VorgangFinished } from '../../lib/shared/status';
import { useSSE } from 'react-hooks-sse';
import styles from '../../styles/basicVariables.module.css';
const Sidebar = (props) => {
	const { user } = useContext(UserContext);
	const router = useRouter();
	const [inboxUpdates, setInboxUpdates] = useState(false);
	const state = useSSE('message');
	const [countsByStatus, setCountsByStatus] = useState(props.countsByStatus || {});

	const Link = (linkProps) => {
		const isActive =
			router.pathname === linkProps.href ||
			(props.vorgang &&
				// Inbox
				((linkProps.inProgress === false &&
					(linkProps.status.includes(props.vorgang.status) ||
						(props.vorgang.nextStep === 'bank' &&
							!VorgangFinished.includes(props.vorgang.status)))) ||
					// In Progress
					(linkProps.inProgress === true &&
						linkProps.status.includes(props.vorgang.status) &&
						props.vorgang.nextStep === 'durchblicker') ||
					// Finished
					(linkProps.isFinished === true && linkProps.status.includes(props.vorgang.status))));
		return (
			<a href={linkProps.href} className={styles[classNames({ isActive: isActive })]}>
				{linkProps.children}
			</a>
		);
	};

	useEffect(() => {
		setCountsByStatus({ ...countsByStatus, ...props.countsByStatus });
	}, [props.countsByStatus]);

	useEffect(() => {
		if (!state?.type) {
			return;
		}

		switch (state?.type) {
			case 'VORGANG_CREATED':
				setInboxUpdates(true);
				if (countsByStatus?.inbox) {
					setCountsByStatus({
						...countsByStatus,
						...{ inbox: countsByStatus.inbox + 1 }
					});
				}
				break;
		}
	}, [state]);

	const RedDot = () => {
		return <div className={styles.redDot} />;
	};

	return (
		<div className={styles.sidebar}>
			<a href='/'>
				<img src='/static/svgs/logo-durchblicker.svg' />
			</a>
			<ul>
				<li>
					<Link href='/' inProgress={false} status={Status.VorgangActionRequired}>
						Inbox ({countsByStatus.inbox})
					</Link>
					{inboxUpdates && <RedDot />}
				</li>
				<li>
					<Link href='/in-progress' inProgress={true} status={Status.VorgangNoActionRequired}>
						Im Prozess ({countsByStatus.inProgress})
					</Link>
				</li>
				<li>
					<Link href='/finished' isFinished={true} status={Status.VorgangFinished}>
						Abgeschlossen ({countsByStatus.finished})
					</Link>
				</li>
			</ul>
			{(user.role === 'admin' || user.role === 'superadmin') && (
				<ul>
					<li>
						<Link href='/settings'>Einstellungen</Link>
					</li>
					<li>
						<Link href='/users'>Benutzer</Link>
					</li>
				</ul>
			)}
			{user.role === 'superadmin' && (
				<ul>
					<li>
						<Link href='/banks'>Banken</Link>
					</li>
				</ul>
			)}
		</div>
	);
};

export default Sidebar;
