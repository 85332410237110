import { formatAmount, formatDate } from '../utils/formatting';
import styles from './index.module.css';
import { useState, useEffect } from 'react';
import { padStart } from 'lodash';

export default function Index(props) {
	const [now, setNow] = useState(new Date());

	useEffect(() => {
		const timer = setInterval(() => {
			setNow(new Date());
		}, 1000);
		return () => clearInterval(timer);
	}, []);

	const delta = (new Date(props.deadline) - now) / 1000;
	let deadlineClassName;

	if (delta < 0) {
		deadlineClassName = styles.deadlinePassed;
	} else if (delta < props.warnungFrist) {
		deadlineClassName = styles.deadlineWarning;
	}

	const timeEntries = [
		...(delta > 86400 ? [padStart(Math.floor(delta / 86400), 2, '0')] : []),
		...(delta > 3600 ? [padStart(Math.floor((delta % 86400) / 3600), 2, '0')] : []),
		...(delta > 60 ? [padStart(Math.floor((delta % 3600) / 60), 2, '0')] : []),
		...[padStart(Math.floor(delta % 60), 2, '0')]
	];

	return (
		<span className={deadlineClassName}>
			{delta > 0 && (
				<>
					{formatDate(props.deadline)}
					&nbsp; (noch {timeEntries.join(':')})
					{/* (noch {Math.floor((delta % 3600) / 60)}:{padStart(Math.floor(delta % 60), 2, '0')} */}
				</>
			)}
			{delta < 0 && 'Frist verstrichen'}
		</span>
	);
}
