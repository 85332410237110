import { formatAmount } from '../../components/utils/formatting';
import {
	getSummeKreditraten, // Summe der Raten aller nicht umgeschuldeten bestehenden Kredite
	getGesamteinkommen, // Durchschnittliches Einkommen der letzten 3 Monate inkl. Zusatzeinkommen
	getSummeAusgaben // Summe der Ausgbaen exkl. Kreditraten
} from '../../components/utils/berechnung';

const Haushalt = (data) => {
	return [
		{
			title: 'Haushaltsrechnung',
			items: [
				{
					key: 'Einkommen',
					value:
						'+ ' +
						formatAmount(
							data.abschluss.person1.einkommen.gleichbleibend
								? data.abschluss.person1.einkommen.monat
								: (data.abschluss.person1.einkommen.monat +
										data.abschluss.person1.einkommen.monat2 +
										data.abschluss.person1.einkommen.monat3) /
										3
						)
				},
				{
					key: 'Zusatzeinkommen',
					value:
						'+ ' +
						formatAmount(
							data.abschluss.person1.einkommen.zusatzeinkommen.vorhanden
								? data.abschluss.person1.einkommen.zusatzeinkommen.unterhalt +
										data.abschluss.person1.einkommen.zusatzeinkommen.mieteinnahme +
										data.abschluss.person1.einkommen.zusatzeinkommen.nebenerwerb +
										data.abschluss.person1.einkommen.zusatzeinkommen.beihilfe +
										data.abschluss.person1.einkommen.zusatzeinkommen.sonstiges
								: 0
						),
					hidden: !data.abschluss.person1.einkommen.zusatzeinkommen.vorhanden
				},
				{
					key: 'Ausgaben',
					value: '- ' + formatAmount(getSummeAusgaben(data))
				},
				{
					key: 'Kreditraten',
					value: '- ' + formatAmount(getSummeKreditraten(data)),
					hidden: getSummeKreditraten(data) === 0
				},
				{
					key: 'Saldo',
					value: formatAmount(
						getGesamteinkommen(data) - getSummeKreditraten(data) - getSummeAusgaben(data)
					),
					type: 'sum'
				}
			]
		},
		{
			title: 'Kennzahlen',
			items: [
				{
					key: 'CRIF-Score',
					value: data.abschluss.person1.crif ? data.abschluss.person1.crif.score : ''
				},
				{
					key: 'DSTI',
					value:
						Math.round(
							((data.ratenkredit.kredit.darlehensbetrag * 0.04 + getSummeKreditraten(data)) /
								(getGesamteinkommen(data) -
									(data.abschluss.person1.ausgaben.personenunterhalt > 0
										? data.abschluss.person1.ausgaben.unterhalt
										: 0))) *
								100
						) / 100
					// (fiktive Kreditrate [4% des Darlehensbetrags] + Raten bestehender Kredite) / (Gesamteinkommen - Unterhaltszahlungen)
				}
			]
		}
	];
};

export default Haushalt;
