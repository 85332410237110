export default {
	af: 'Afghanistan',
	eg: 'Ägypten',
	al: 'Albanien',
	dz: 'Algerien',
	as: 'Amerikanisch-Samoa',
	vi: 'Amerikanische Jungferninseln',
	ad: 'Andorra',
	ao: 'Angola',
	ai: 'Anguilla',
	aq: 'Antarktis',
	ag: 'Antigua und Barbuda',
	gq: 'Äquatorialguinea',
	ar: 'Argentinien',
	am: 'Armenien',
	aw: 'Aruba',
	az: 'Aserbaidschan',
	et: 'Äthiopien',
	au: 'Australien',
	bs: 'Bahamas',
	bh: 'Bahrain',
	bd: 'Bangladesch',
	bb: 'Barbados',
	be: 'Belgien',
	bz: 'Belize',
	bj: 'Benin',
	bm: 'Bermuda',
	bt: 'Bhutan',
	bo: 'Bolivien',
	ba: 'Bosnien und Herzegowina',
	bw: 'Botswana',
	bv: 'Bouvetinsel',
	br: 'Brasilien',
	vg: 'Britische Jungferninseln',
	io: 'Britisches Territorium im indischen Ozean',
	bn: 'Brunei',
	bg: 'Bulgarien',
	bf: 'Burkina Faso',
	bi: 'Burundi',
	cl: 'Chile',
	cn: 'China',
	ck: 'Cookinseln',
	ct: 'Coral Island',
	cr: 'Costa Rica',
	dk: 'Dänemark',
	de: 'Deutschland',
	dm: 'Dominica',
	do: 'Dominikanische Republik',
	dj: 'Dschibuti',
	ec: 'Ecuador',
	sv: 'El Salvador',
	ci: 'Elfenbeinküste',
	er: 'Eritrea',
	ee: 'Estland',
	fk: 'Falklandinseln',
	fo: 'Färöer',
	fj: 'Fidschi',
	fi: 'Finnland',
	fr: 'Frankreich',
	gf: 'Französisch-Guayana',
	pf: 'Französisch-Polynesien',
	tf: 'Französische Süd- und Antarktisgebiete',
	ga: 'Gabun',
	gm: 'Gambia',
	ge: 'Georgien',
	gh: 'Ghana',
	gi: 'Gibraltar',
	gd: 'Grenada',
	gr: 'Griechenland',
	gl: 'Grönland',
	gb: 'Grossbritannien',
	gp: 'Guadeloupe',
	gu: 'Guam',
	gt: 'Guatemala',
	gn: 'Guinea',
	gw: 'Guinea-Bissau',
	gy: 'Guyana',
	ht: 'Haiti',
	hm: 'Heard und McDonaldinseln',
	hn: 'Honduras',
	hk: 'Hong Kong',
	in: 'Indien',
	id: 'Indonesien',
	iq: 'Irak',
	ir: 'Iran',
	ie: 'Irland',
	is: 'Island',
	im: 'Isle of Man',
	il: 'Israel',
	it: 'Italien',
	jm: 'Jamaika',
	jp: 'Japan',
	ye: 'Jemen',
	jo: 'Jordanien',
	ky: 'Kaimaninseln',
	kh: 'Kambodscha',
	cm: 'Kamerun',
	ca: 'Kanada',
	cv: 'Kap Verde',
	kz: 'Kasachstan',
	qa: 'Katar',
	ke: 'Kenia',
	kg: 'Kirgisistan',
	ki: 'Kiribati',
	cc: 'Kokosinseln',
	co: 'Kolumbien',
	km: 'Komoren',
	cd: 'Kongo, Demokratische Republik',
	cg: 'Kongo, Republik',
	kp: 'Korea, Demokratische Volksrepublik',
	kr: 'Korea, Republik',
	ko: 'Kosovo',
	hr: 'Kroatien',
	cu: 'Kuba',
	kw: 'Kuwait',
	la: 'Laos',
	ls: 'Lesotho',
	lv: 'Lettland',
	lb: 'Libanon',
	lr: 'Liberia',
	ly: 'Libyen',
	li: 'Liechtenstein',
	lt: 'Litauen',
	lu: 'Luxemburg',
	mo: 'Macao',
	mg: 'Madagascar',
	mi: 'Madeira',
	mw: 'Malawi',
	my: 'Malaysia',
	mv: 'Maldiven',
	ml: 'Mali',
	mt: 'Malta',
	ma: 'Marokko',
	mh: 'Marshallinseln',
	mq: 'Martinique',
	mr: 'Mauretanien',
	mu: 'Mauritius',
	yt: 'Mayotte',
	mk: 'Mazedonien',
	mx: 'Mexiko',
	fm: 'Mikronesien',
	md: 'Moldawien',
	mc: 'Monaco',
	mn: 'Mongolei',
	me: 'Montenegro',
	ms: 'Montserrat',
	mz: 'Mosambik',
	mm: 'Myanmar',
	na: 'Namibia',
	nr: 'Nauru',
	np: 'Nepal',
	nc: 'Neukaledonien',
	nz: 'Neuseeland',
	ni: 'Nicaragua',
	nl: 'Niederlande',
	an: 'Niederländische Antillen',
	ne: 'Niger',
	ng: 'Nigeria',
	nu: 'Niue',
	mp: 'Nördliche Marianen',
	nf: 'Norfolkinseln',
	no: 'Norwegen',
	om: 'Oman',
	at: 'Österreich',
	pk: 'Pakistan',
	ps: 'Palästinensische Autonomiegebiete',
	pw: 'Palau',
	pa: 'Panama',
	pg: 'Papua-Neuguinea',
	py: 'Paraguay',
	pe: 'Peru',
	ph: 'Philippinen',
	pn: 'Pitcairninseln',
	pl: 'Polen',
	pt: 'Portugal',
	pr: 'Puerto Rico',
	re: 'Réunion',
	rw: 'Ruanda',
	ro: 'Rumänien',
	ru: 'Russland',
	sb: 'Salomonen',
	zm: 'Sambia',
	ws: 'Samoa',
	sm: 'San Marino',
	st: 'São Tomé und Príncipe',
	sa: 'Saudi-Arabien',
	se: 'Schweden',
	ch: 'Schweiz',
	sn: 'Senegal',
	rs: 'Serbien',
	sc: 'Seychellen',
	zw: 'Simbabwe',
	sg: 'Singapur',
	sk: 'Slowakei',
	si: 'Slowenien',
	so: 'Somalia',
	es: 'Spanien',
	lk: 'Sri Lanka',
	sh: 'St. Helena',
	kn: 'St. Kitts und Nevis',
	lc: 'St. Lucia',
	pm: 'St. Pierre and Miquelon',
	vc: 'St. Vincent und die Grenadinen',
	za: 'Südafrika',
	sd: 'Sudan',
	gs: 'Südgeorgien',
	sr: 'Suriname',
	sj: 'Svalbard und Jan Mayen',
	sz: 'Swasiland',
	sy: 'Syrien',
	tj: 'Tadschikistan',
	tw: 'Taiwan',
	tz: 'Tansania',
	th: 'Thailand',
	tl: 'Timor-Leste',
	tg: 'Togo',
	tk: 'Tokelau',
	to: 'Tonga',
	tt: 'Trinidad und Tobago',
	td: 'Tschad',
	cz: 'Tschechische Republik',
	tn: 'Tunesien',
	tr: 'Türkei',
	tm: 'Turkmenistan',
	tc: 'Turks- und Caicosinseln',
	tv: 'Tuvalu',
	ug: 'Uganda',
	ua: 'Ukraine',
	hu: 'Ungarn',
	uy: 'Uruguay',
	uz: 'Usbekistan',
	vu: 'Vanuatu',
	va: 'Vatikan',
	ve: 'Venezuela',
	ae: 'Vereinigte Arabische Emirate',
	us: 'Vereinigte Staaten von Amerika',
	vn: 'Vietnam',
	wf: 'Wallis und Futuna',
	cx: 'Weihnachtsinsel',
	by: 'Weissrussland',
	eh: 'Westsahara',
	cf: 'Zentralafrikanische Republik',
	cy: 'Zypern'
};
