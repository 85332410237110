import { startCase, capitalize, padStart } from 'lodash';
import { Vorgang } from '../../lib/shared/status';

export const formatBearbeiterName = (bearbeiter) => {
	return `${bearbeiter.firstname} ${bearbeiter.lastname}${
		bearbeiter.deletedAt ? ' [Gelöscht]' : ''
	}`;
};

export const formatAmount = (amount) => {
	return (
		'€ ' +
		Number(amount)
			.toFixed(2)
			.replace('.', ',')
			.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')
	);
};

export const formatZins = (zins) => {
	return zins.toFixed(2).replace('.', ',');
};

export const formatDate = (date) => {
	if (date === null) {
		return '';
	}

	const internalDate = date instanceof Date ? date : new Date(date);
	const dateParts = [
		internalDate.getDate(),
		internalDate.getMonth() + 1,
		internalDate.getFullYear()
	].map((e) => padStart(e, 2, '0'));

	const timePart = [internalDate.getHours(), internalDate.getMinutes()].map((e) =>
		padStart(e, 2, '0')
	);

	return `${dateParts.join('.')} ${timePart.join(':')}`;
};

export const formatStatus = (status) => {
	const map = {};
	map[Vorgang.OFFEN] = 'Offen';
	map[Vorgang.VERTRAG_GEWUENSCHT] = 'Vertrag gewünscht';
	map[Vorgang.PRUEFUNG_NEGATIV] = 'Prüfung negativ';
	map[Vorgang.RUECKTRITT] = 'Rücktritt';
	map[Vorgang.WIRD_FORTGEFUEHRT] = 'Von Bank weitergeführt';
	map[Vorgang.KUNDEN_RUECKTRITT] = 'Kundenrücktritt';

	return map[status] || capitalize(startCase(status));
};
