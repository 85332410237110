const annahme = (data) => {
	return [
		{
			items: [
				{
					key: 'vorgangId',
					default: data.id,
					type: 'text',
					hidden: true
				},
				{
					key: 'anbieterid',
					default: data.anbieterid,
					type: 'text',
					hidden: true
				},
				{
					key: 'oppTransaction',
					default: data.oppTransaction,
					type: 'text',
					hidden: true
				},
				{
					key: 'calculation',
					default: data.calculation,
					type: 'text',
					hidden: true
				},
				{
					key: 'type',
					type: 'select',
					name: 'Konditionstyp',
					// default: 'kondition',
					default: undefined,
					options: [
						{
							name: 'Exakt',
							value: 'kondition'
						},
						{
							name: 'Upsell',
							value: 'upsell'
						},
						{
							name: 'Downsell',
							value: 'downsell'
						}
					],
					validate: (find, self, context) => {
						const conditions = context.props.conditions;
						const anzahlKonditionFix = conditions.filter(condition => condition.type === 'kondition' && condition.zinsform === 'fix').length;
						const anzahlDownsellFix = conditions.filter(condition => condition.type === 'downsell' && condition.zinsform === 'fix').length;
						const anzahlUpsellFix = conditions.filter(condition => condition.type === 'upsell' && condition.zinsform === 'fix').length;;
						const anzahlKonditionWithSecondBorrowerFix = conditions.filter(condition => condition.type === 'kondition' && condition.offerWithSecondBorrower && condition.zinsform === 'fix').length;
						const anzahlKonditionVariabel = conditions.filter(condition => condition.type === 'kondition' && condition.zinsform === 'variabel').length;
						const anzahlDownsellVariabel = conditions.filter(condition => condition.type === 'downsell' && condition.zinsform === 'variabel').length;
						const anzahlUpsellVariabel = conditions.filter(condition => condition.type === 'upsell' && condition.zinsform === 'variabel').length;;
						const anzahlKonditionWithSecondBorrowerVariabel = conditions.filter(condition => condition.type === 'kondition' && condition.offerWithSecondBorrower && condition.zinsform === 'variabel').length;
						const type = find('type', self);

						if (anzahlKonditionFix > 0 && anzahlKonditionVariabel > 0 && type === 'kondition') {
							return {
								error: true,
								message: 'Es sind bereits 2 exakte Konditionen vorhanden'
							};
						} else if (
								(anzahlDownsellFix + anzahlKonditionFix - anzahlKonditionWithSecondBorrowerFix >= 1) &&
								(anzahlDownsellVariabel + anzahlKonditionVariabel - anzahlKonditionWithSecondBorrowerVariabel >= 1) &&
								type === 'downsell'
							) {
							return {
								error: true,
								message: 'Es kann kein weiteres Downsell Angebot erstellt werden'
							};
						} else if (
							anzahlKonditionFix <= anzahlUpsellFix &&
							anzahlKonditionVariabel <= anzahlUpsellVariabel &&
							type === 'upsell'
						) {
							return {
								error: true,
								message: 'Es gibt noch keine exakte Kondition für dieses Upsell Angebot'
							};
						} else {
							return {
								error: false,
								message: ''
							};
						}
					}
				},
				{
					key: 'verwendungszweck',
					type: 'select',
					name: 'Verwendungszweck',
					default: 'beliebig',
					hidden: true,
					options: [
						{
							name: 'Beliebig',
							value: 'beliebig'
						},
						{
							name: 'Auto',
							value: 'auto'
						},
						{
							name: 'Wohnen',
							value: 'wohnen'
						}
					]
				},
				{
					key: 'darlehensbetrag',
					type: 'number',
					dataType: 'integer',
					// default: data.darlehensbetrag,
					name: 'Kreditbetrag (in €)',
					setValue: (find, self) => {
						if (find('type', self) === 'kondition') {
							return data.darlehensbetrag;
						} else {
							return find('darlehensbetrag', self);
						}
					},
					depend: (find, self) => {
						return find('type', self) !== 'kondition';
					},
					validate: (find, self, context) => {
						const value = parseInt(find('darlehensbetrag', self), 10);
						const laufzeit = parseInt(find('laufzeit', self), 10);
						const type = find('type', self);
						if (
							type === 'downsell' &&
							laufzeit === context.props.vorgang.laufzeit &&
							value >= context.props.vorgang.darlehensbetrag
						) {
							return {
								error: true,
								message:
									'Der Betrag muss kleiner als der Kreditbetrag oder die Laufzeit abweichend sein.'
							};
						} else if (type === 'upsell' && value <= context.props.vorgang.darlehensbetrag) {
							return {
								error: true,
								message: 'Der Betrag muss höher als der Kreditbetrag sein.'
							};
						} else if (
							type !== 'kondition' &&
							value === context.props.vorgang.darlehensbetrag &&
							laufzeit === context.props.vorgang.laufzeit
						) {
							return {
								error: true,
								message:
									'Es muss ein vom angefragten Kreditbetrag abweichender Wert eingetragen werden'
							};
						}
						/*
						else if (type === 'downsell' && value > context.props.vorgang.darlehensbetrag) {
							return {
								error: true,
								message: 'Der Betrag muss kleiner als der Kreditbetrag sein.'
							};
						}
						*/
						return {
							error: value <= 0,
							message: 'Bitte Kreditbetrag eingeben'
						};
					}
				},
				{
					key: 'auszahlungsbetrag',
					type: 'number',
					dataType: 'integer',
					default: 0,
					name: 'Auszahlungsbetrag (in €)',
					hidden: true,
					validate: (find, self) => {
						return {
							error: find('auszahlungsbetrag', self) <= 0,
							message: 'Bitte Kreditbetrag eingeben'
						};
					}
				},
				{
					key: 'laufzeit',
					type: 'select',
					dataType: 'integer',
					name: 'Laufzeit (in Monaten)',
					default: data.laufzeit,
					options: [
						{
							name: '1',
							value: 1
						},
						{
							name: '6',
							value: 6
						},
						{
							name: '12',
							value: 12
						},
						{
							name: '24',
							value: 24
						},
						{
							name: '36',
							value: 36
						},
						{
							name: '48',
							value: 48
						},
						{
							name: '60',
							value: 60
						},
						{
							name: '72',
							value: 72
						},
						{
							name: '84',
							value: 84
						},
						{
							name: '96',
							value: 96
						},
						{
							name: '108',
							value: 108
						},
						{
							name: '120',
							value: 120
						}
					],
					depend: (find, self) => {
						return find('type', self) !== 'kondition';
					},
					setValue: (find, self) => {
						if (find('type', self) === 'kondition') {
							return data.laufzeit;
						} else {
							return find('laufzeit', self);
						}
					},
					validate: (find, self) => {
						return {
							error: find('laufzeit', self) <= 0,
							message: 'Bitte Laufzeit eingeben'
						};
					}
				},
				{
					key: 'zinsform',
					type: 'select',
					name: 'Zinsart',
					default: 'variabel',
					disabled: false,
					options: [
						{
							name: 'Fix',
							value: 'fix'
						},
						{
							name: 'Variabel',
							value: 'variabel'
						}
					],
					validate: (find, self, context) => {
						let fixVorhanden = false;
						let variabelVorhanden = false;
						const type = find('type', self);
						const zinsform = find('zinsform', self);
						const laufzeit = find('laufzeit', self);
						const conditions = context.props.conditions;
						const anzahlKonditionFix = conditions.filter(condition => condition.type === 'kondition' && condition.zinsform === 'fix').length;
						const anzahlDownsellFix = conditions.filter(condition => condition.type === 'downsell' && condition.zinsform === 'fix').length;
						const anzahlUpsellFix = conditions.filter(condition => condition.type === 'upsell' && condition.zinsform === 'fix').length;;
						const anzahlKonditionWithSecondBorrowerFix = conditions.filter(condition => condition.type === 'kondition' && condition.offerWithSecondBorrower && condition.zinsform === 'fix').length;
						const anzahlKonditionVariabel = conditions.filter(condition => condition.type === 'kondition' && condition.zinsform === 'variabel').length;
						const anzahlDownsellVariabel = conditions.filter(condition => condition.type === 'downsell' && condition.zinsform === 'variabel').length;
						const anzahlUpsellVariabel = conditions.filter(condition => condition.type === 'upsell' && condition.zinsform === 'variabel').length;;
						const anzahlKonditionWithSecondBorrowerVariabel = conditions.filter(condition => condition.type === 'kondition' && condition.offerWithSecondBorrower && condition.zinsform === 'variabel').length;
						context.props.conditions.forEach(condition => {
							if (condition.type === type) {
								switch (condition.zinsform) {
									case 'fix': {
										fixVorhanden = true;
										break;
									}
									case 'variabel': {
										variabelVorhanden = true;
										break;
									}
								}
							}
						});

						if (
							zinsform === 'fix' &&
							laufzeit > 84
						) {
							return {
								error: true,
								message: 'Max. Laufzeit 7 Jahre für Zinsform fix'
							};		
						} else if (
							type === 'upsell' && (
								(anzahlKonditionFix <= anzahlUpsellFix && zinsform === 'fix') ||
								(anzahlKonditionVariabel <= anzahlUpsellVariabel && zinsform === 'variabel')
							)
						) {
							return {
								error: true,
								message: 'Noch keine exakte Kondition mit dieser Zinsart vorhanden'
							};
						} else if (
							type === 'downsell' && (
								(anzahlDownsellFix + anzahlKonditionFix - anzahlKonditionWithSecondBorrowerFix >= 1 && zinsform === 'fix') ||
								(anzahlDownsellVariabel + anzahlKonditionVariabel - anzahlKonditionWithSecondBorrowerVariabel >= 1 && zinsform === 'variabel')
							)
						) {
							return {
								error: true,
								message: 'Kein Downsell mit dieser Zinsart mehr möglich'
							};
						} else {
							return {
								error: 
									(fixVorhanden && zinsform === 'fix') ||
									(variabelVorhanden && zinsform === 'variabel'),
								message: 'Kondition mit dieser Zinsart bereits vorhanden'
							};
						}
					}
				},
				{
					key: 'nominalzinssatz',
					type: 'number',
					dataType: 'float',
					name: 'Nominalzinssatz (in %)',
					validate: (find, self) => {
						if (parseInt(find('nominalzinssatz', self)) >= 22) {
							return {
								error: true,
								message: 'Bitte einen korrekten Zinssatz eingeben'
							};
						}
						return {
							error: find('nominalzinssatz', self) <= 0,
							message: 'Bitte Nominalzinssatz eingeben'
						};
					}
				},
				{
					key: 'effektivzinssatz',
					type: 'number',
					dataType: 'float',
					name: 'Effektivzinssatz (in %)',
					validate: (find, self) => {
						if (parseInt(find('effektivzinssatz', self)) >= 22) {
							return {
								error: true,
								message: 'Bitte einen korrekten Zinssatz eingeben'
							};
						}
						return {
							error: find('effektivzinssatz', self) <= 0,
							message: 'Bitte Effektivzinssatz eingeben'
						};
					}
				},
				{
					key: 'gebuehrenEinmal',
					type: 'number',
					dataType: 'float',
					name: 'Gebühren einmalig (in €)',
					default: 0,
					hidden: true
				},
				{
					key: 'gebuehrenLaufend',
					type: 'number',
					dataType: 'float',
					name: 'Gebühren laufend (in €)',
					default: 0,
					hidden: true
				},
				{
					key: 'gebuehrenLaufendIntervall',
					type: 'select',
					name: 'Intervall laufender Gebühren',
					dataType: 'integer',
					default: 1,
					hidden: true,
					options: [
						{
							name: 'Monatlich',
							value: 1
						},
						{
							name: 'Quartalsweise',
							value: 3
						},
						{
							name: 'Halbjährlich',
							value: 6
						},
						{
							name: 'Jährlich',
							value: 12
						}
					],
					depend: (find, self) => {
						return JSON.parse(find('gebuehrenLaufend', self) > 0);
					}
				},
				{
					key: 'rate',
					type: 'number',
					dataType: 'float',
					name: 'Monatliche Rate (in €)',
					validate: (find, self, context) => {
						const type = find('type', self);
						const rate = find('rate', self);
						const laufzeit = find('laufzeit', self);
						const gesamt = rate * laufzeit;
						const darlehensbetrag =
							type === 'kondition'
								? context.props.vorgang.darlehensbetrag
								: parseInt(find('darlehensbetrag', self), 10);
						if (gesamt < darlehensbetrag) {
							return {
								error: true,
								message: `Mit dieser Rate und einer Laufzeit von ${context.props.vorgang.laufzeit} Monaten ergibt sich ein zu kleiner Darlehensbetrag.`
							};
						}

						// check if this rate would mean a too big effektivzins
						const effectiveRate =
							(((gesamt - darlehensbetrag) / darlehensbetrag) * 24) / (laufzeit + 1);

						if (effectiveRate >= 0.22) {
							return {
								error: true,
								message: 'Diese Rate führt zu einem unrealistischem Zinssatz.'
							};
						}

						return {
							error: find('rate', self) <= 0,
							message: 'Bitte Rate eingeben'
						};
					}
				},
				{
					key: 'letzteRate',
					type: 'number',
					dataType: 'float',
					name: 'Abweichende letzte Rate (in €)',
					hidden: true
				},
				{
					key: 'gesamtbelastung',
					type: 'number',
					dataType: 'float',
					name: 'Gesamtbelastung (in €)',
					disabled: false,
					validate: (find, self, context) => {
						const existingBelastungen = context.props.conditions.map(
							(item) => item.gesamtbelastung
						);
						const existingZinsart = context.props.conditions.map(
							(item) => item.zinsform
						);
						var laufzeit = find('laufzeit', self);
						var rate = find('rate', self);
						var letzteRate = find('letzteRate', self);

						let value = laufzeit * rate;

						if (letzteRate > 0) {
							value = laufzeit * rate - (rate - letzteRate);
						}

						if (existingBelastungen.includes(value) && existingZinsart.includes(find('zinsform', self))) {
							return {
								error: true,
								message: 'Es gibt bereits eine Kondition mit diesen Beträgen'
							};
						}
						return {
							error: find('gesamtbelastung', self) <= 0,
							message: 'Bitte Gesamtbelastung eingeben'
						};
					}
				},
				{
					key: 'gueltigkeit',
					type: 'date',
					name: 'Angebot gültig bis',
					default: new Date(Date.now() + (58 * 24 * 60 * 60 * 1000)).toISOString().split('T')[0], // 58 Tage = 60 Tage - 2 Tage Puffer
					hidden: true,
					validate: (find, self) => {
						const getDate = (date) => new Date(date).toISOString().split('T')[0].replace(/-/g, '');
						return {
							error: getDate(find('gueltigkeit', self)) < getDate(new Date()),
							message: 'Datum liegt in der Vergangenheit'
						};
					}
				},
				{
					key: 'offerWithSecondBorrower',
					type: 'select',
					dataType: 'boolean',
					name: 'Angebot nur mit 2. Kreditnehmer',
					default: false,
					options: [
						{
							name: 'Ja',
							value: true
						},
						{
							name: 'Nein',
							value: false
						}
					]
				},
				{
					key: 'expressKredit',
					type: 'select',
					dataType: 'boolean',
					name: 'Expresskredit',
					default: false,
					options: [
						{
							name: 'Ja',
							value: true
						},
						{
							name: 'Nein',
							value: false
						}
					],
					depend: (find, self, context) => {
						const type = find('type', self);
						const darlehensbetrag =
						type === 'kondition'
							? data.darlehensbetrag
							: parseInt(find('darlehensbetrag', self), 10);
						if (type !== 'kondition' || darlehensbetrag >= 1500 && darlehensbetrag <= 5000) {
							return true;
						} else {
							return false;
						}
					},
					validate: (find, self, context) => {
						const value = find('expressKredit', self) === 'true';
						const darlehensbetrag =
						(find('type', self) === 'kondition'
							? context.props.vorgang.darlehensbetrag
							: parseInt(find('darlehensbetrag', self), 10));
						if (value && !(darlehensbetrag >= 1500 && darlehensbetrag <= 5000)) {
							return {
								error: true,
								message:'Expresskredit ist bei diesem Betrag nicht möglich'
							};
						} 

						
					}
				}
			]
		},
		{
			title: 'Restschuldversicherung - RSV',
			hidden: true,
			items: [
				{
					key: 'rsv',
					type: 'radio',
					dataType: 'boolean',
					name: 'RSV enthalten',
					default: false,
					options: [
						{
							name: 'Ja',
							value: true
						},
						{
							name: 'Nein',
							value: false
						}
					]
				},
				{
					key: 'rsvPraemie',
					type: 'number',
					name: 'RSV Prämie (in €)',
					dataType: 'float',
					depend: (find, self) => {
						return JSON.parse(find('rsv', self));
					}
				},
				{
					key: 'rsvDeckung',
					type: 'select',
					name: 'RSV Deckung',
					default: 'ableben',
					options: [
						{
							name: 'Ableben',
							value: 'ableben'
						},
						{
							name: 'Ableben + Berufsunfähigkeit',
							value: 'berufsunfaehigkeit'
						}
					],
					depend: (find, self) => {
						return JSON.parse(find('rsv', self));
					}
				}
			]
		}
	];
};

export default annahme;
