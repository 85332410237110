// Summe der Raten aller nicht umgeschuldeten bestehenden Kredite
export const getSummeKreditraten = (data) => {
	let summeKreditraten = 0;
	if (data.abschluss.kredite.anzahl > 0) {
		switch (data.abschluss.kredite.anzahl) {
			case '1':
				summeKreditraten = data.abschluss.kredite.kredit1.umschuldung
					? 0
					: data.abschluss.kredite.kredit1.rate;
				break;
			case '2':
				summeKreditraten =
					(data.abschluss.kredite.kredit1.umschuldung ? 0 : data.abschluss.kredite.kredit1.rate) +
					(data.abschluss.kredite.kredit2.umschuldung ? 0 : data.abschluss.kredite.kredit2.rate);
				break;
			case '3':
				summeKreditraten =
					(data.abschluss.kredite.kredit1.umschuldung ? 0 : data.abschluss.kredite.kredit1.rate) +
					(data.abschluss.kredite.kredit2.umschuldung ? 0 : data.abschluss.kredite.kredit2.rate) +
					(data.abschluss.kredite.kredit3.umschuldung ? 0 : data.abschluss.kredite.kredit3.rate);
				break;
		}
	}
	return summeKreditraten;
};

// Durchschnittliches Einkommen der letzten 3 Monate inkl. Zusatzeinkommen
export const getGesamteinkommen = (data) => {
	let gesamteinkommen;

	gesamteinkommen = data.abschluss.person1.einkommen.gleichbleibend
		? data.abschluss.person1.einkommen.monat
		: (data.abschluss.person1.einkommen.monat +
				data.abschluss.person1.einkommen.monat2 +
				data.abschluss.person1.einkommen.monat3) /
		  3;
	if (data.abschluss.person1.einkommen.zusatzeinkommen.vorhanden) {
		gesamteinkommen +=
			data.abschluss.person1.einkommen.zusatzeinkommen.unterhalt +
			data.abschluss.person1.einkommen.zusatzeinkommen.mieteinnahme +
			data.abschluss.person1.einkommen.zusatzeinkommen.nebenerwerb +
			data.abschluss.person1.einkommen.zusatzeinkommen.beihilfe +
			data.abschluss.person1.einkommen.zusatzeinkommen.sonstiges;
	}

	return gesamteinkommen;
};

// Summe Ausgaben exkl. Kreditraten
export const getSummeAusgaben = (data) => {
	return (
		data.abschluss.person1.ausgaben.wohnkosten +
		(data.abschluss.person1.ausgaben.personenunterhalt > 0
			? data.abschluss.person1.ausgaben.unterhalt
			: 0)
	);
};
