import { useContext } from 'react';
import UserContext from '../../UserContext';

export const DemoButton = ({ children, ...props }) => {
	const { user } = useContext(UserContext);

	return null;

	// if (![1, 2].includes(user.bankId)) {
	// 	return null;
	// }
	// return <button {...props}>{children}</button>;
};
