import { useContext } from 'react';
import UserContext from '../components/UserContext';

// Bank Austria
import bankAustriaAblehnung from './bankAustria/ablehnung';
import bankAustriaRuecktritt from './bankAustria/ruecktritt';
import bankAustriaAnnahme from './bankAustria/annahme';
import bankAustriaHaushalt from './bankAustria/haushalt';
import bankAustriaInput from './bankAustria/input';

// Demo Bank
import demobankAblehnung from './demobank/ablehnung';
import demobankRuecktritt from './demobank/ruecktritt';
import demobankAnnahme from './demobank/annahme';
import demobankHaushalt from './demobank/haushalt';
import demobankInput from './demobank/input';

// Default Forms
import defaultAblehnung from './default/ablehnung';
import defaultRuecktritt from './default/ruecktritt';
import defaultAnnahme from './default/annahme';
import defaultHaushalt from './default/haushalt';
import defaultInput from './default/input';

const forms = {
	bankAustria: {
		ablehnung: bankAustriaAblehnung,
		ruecktritt: bankAustriaRuecktritt,
		annahme: bankAustriaAnnahme,
		haushalt: bankAustriaHaushalt,
		input: bankAustriaInput
	},
	demobank: {
		ablehnung: demobankAblehnung,
		ruecktritt: demobankRuecktritt,
		annahme: demobankAnnahme,
		haushalt: demobankHaushalt,
		input: demobankInput
	},
	default: {
		ablehnung: defaultAblehnung,
		ruecktritt: defaultRuecktritt,
		annahme: defaultAnnahme,
		haushalt: defaultHaushalt,
		input: defaultInput
	}
};

const mergeForms = (first, second) => {
	if (Array.isArray(second[0].items)) {
		first.forEach((firstSection, firstSectionIndex) => {
			second.forEach((secondSection, secondSectionIndex) => {
				if (firstSection.title === secondSection.title) {
					second[secondSectionIndex].hidden = first[firstSectionIndex].hidden;
					const keys = firstSection.items.map((item) => item.key);
					secondSection.items.forEach((item, itemIndex) => {
						const index = keys.indexOf(item.key);
						if (index > -1) {
							second[secondSectionIndex].items[itemIndex] = first[firstSectionIndex].items[index];
						}
					});
				}
			});
		});
	} else {
		const keys = first.map((item) => item.key);
		second.forEach((item, itemIndex) => {
			const index = keys.indexOf(item.key);
			if (index > -1) {
				second[itemIndex] = first[index];
			}
		});
	}
	return second;
};

const getFormData = (type, props, user) => {
	if (!forms[user.bank.bankCode]) {
		return forms.default[type](props);
	}
	return mergeForms(
		forms[user.bank.bankCode] ? forms[user.bank.bankCode][type](props) : {},
		forms.default[type](props)
	);
};

export const getFormDataAsCsv = (items, user) => {
	const csvData = {};
	getFormData('input', items, user).forEach((section) => {
		return section.items.forEach((item) => {
			csvData[`${section.title} ${item.key}`] = item.value;
		});
	});
	return csvData;
};

export default getFormData;
