import UserContext from '../UserContext';
import { useContext, useCallback } from 'react';
import _ from 'underscore';
import styles from '../../styles/basicVariables.module.css';
export default function NavBar(props) {
	const { user, signOut } = useContext(UserContext);

	const doSignOut = (e) => {
		e.preventDefault();
		signOut();
	};

	const onSearchChangeHandler = useCallback(_.debounce(props.onSearchChange, 300), [
		props.onSearchChange
	]);
	const onSearchChange = (e) => {
		onSearchChangeHandler(e.currentTarget.value);
	};

	const onFilterOwnChange = (e) => {
		props.onFilterOwnChange(e.currentTarget.checked);
	};

	return (
		<div className={styles.navbar}>
			<div className={styles.leftColumn}>
				{props.onSearchChange && (
					<div className={styles.flexFirst}>
						<input
							onChange={onSearchChange}
							type='text'
							placeholder='Suche: z.B. Vorgangsnummer'
							autoFocus
						/>
					</div>
				)}

				{props.onFilterOwnChange && (
					<div className={styles.flexEven}>
						<label>
							<input type='checkbox' onChange={onFilterOwnChange} />
							<span>Nur meine anzeigen</span>
						</label>
					</div>
				)}
			</div>
			<div className={styles.rightColumn}>
				{user && (
					<span>
						{user.firstname} {user.lastname}
					</span>
				)}
				{user && (
					<a href='#' onClick={doSignOut}>
						Logout
					</a>
				)}
			</div>
		</div>
	);
}
