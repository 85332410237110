export default {
	administrationVerwaltung: 'Administration und Verwaltung',
	bau: 'Bau',
	druckFotoTon: 'Druck, Papier, Foto, Ton',
	energieversorgung: 'Energieversorgung',
	erziehungUnterricht: 'Erziehung und Unterricht',
	finanzenVersicherung: 'Finanzen und Versicherung',
	gesundheitSozialwesen: 'Gesundheits- und Sozialwesen',
	handel: 'Handel',
	herstellungBekleidung: 'Herstellung von Bekleidung',
	herstellungNahrungFuttermittel: 'Herstellung von Nahrungs- und Futtermitteln',
	herstellungTextilien: 'Herstellung von Textilien',
	herstellungWaren: 'Herstellung von Waren',
	hotelGastronomie: 'Hotel und Gastronomie',
	it: 'IT',
	kfz: 'KFZ',
	landUndForstwirtschaft: 'Land- und Forstwirtschaft',
	medien: 'Medien',
	sonstigeDienstleistungen: 'sonstige Dienstleistungen',
	transportLogistik: 'Transport und Logistik',
	waeschereiChemischeReinigung: 'Wäscherei und chemische Reinigung',
	wasserversorgung: 'Wasserversorgung',
	sonstige: 'Sonstige'
};
