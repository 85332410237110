export default {
	bvaeb: 'BVAEB - Versicherungsanstalt öffentlich Bediensteter, Eisenbahnen und Bergbau',
	svs: 'SVS - Sozialversicherungsanstalt der Selbständigen',
	ma2: 'MA 2 - Personalservice',
	oebb: 'ÖBB Pensionsservice',
	post: 'Österreichische Post AG',
	pvaBurgenland: 'PVA Landesstelle Burgenland',
	pvaKaernten: 'PVA Landesstelle Kärnten',
	pvaNiederoesterreich: 'PVA Landesstelle Niederösterreich',
	pvaOberoesterreich: 'PVA Landesstelle Oberösterreich',
	pvaSalzburg: 'PVA Landesstelle Salzburg',
	pvaSteiermark: 'PVA Landesstelle Steiermark',
	pvaTirol: 'PVA Landesstelle Tirol',
	pvaWien: 'PVA Landesstelle Wien',
	pvaVorarlberg: 'PVA Landesstelle Vorarlberg',
	stadtwerkeWien: 'Wiener Stadtwerke',
	andere: 'Andere'
};
